import $ from 'jquery'

export function getURLParams(location) {
  const data = {}
  const params = new URLSearchParams(location)

  params.forEach((paramValue, paramName) => {
    data[paramName] = paramValue
  })

  return data
}

export function getCurrentLanguage() {
  return `${localStorage.getItem('landing-lang') || 'en'}_FR`
}

export function getApi() {
  return `${process.env.REACT_APP_LANDING_API}/${getCurrentLanguage()}/api/landing`
}

export function converObjectToParams(data) {
  return $.param(data)
}
