import axios from 'axios'
import {getApi} from 'utils/getData'
import i18n from '../i18n'

// create an axios instance
const service = axios.create({
  baseURL: getApi(), // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000, // request timeout
})

i18n.on('languageChanged', () => {
  service.defaults.baseURL = getApi()
})

service.interceptors.request.use(
  config => config,
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// response interceptor
service.interceptors.response.use(
  response => response,
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

export default service
