import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {changeYoutubeUrl} from 'utils'
import {fetchArticleByID} from 'api/articles'

import SecondHeader from 'components/SecondHeader'
import Spinner from 'components/Spinner'
import Footer from 'components/Footer'

import './style.scss'

function Article(props) {
  const {t, i18n} = useTranslation()
  const [article, setArticle] = useState(null)

  useEffect(() => {
    fetchArticleByID(props.match.params.id)
      .then(({data}) => {
        console.log(data)
        setArticle(data)
      })
  }, [i18n.language])

  return (
    <>
      <SecondHeader />

      <div className={`wrapper-article ${article ? '' : 'article-spinner'}`}>
        {
          article
            ? (
              <>
                <section className="article-title-container">
                  <div className="article-title-wrapper">
                    <div className="caption">
                      <p>{t('ARTICLES')}</p>
                      <img src="/images/icons/right.png" alt="" />
                    </div>
                    <div className="article-title">{article.title}</div>
                  </div>
                </section>

                <section className="content">
                  <section className="article">
                    <div className="wrapper-image-video">
                      {
                        article.video && article.video.length > 0
                          ? (
                            <div className="article-video">
                              <iframe
                                title="video"
                                width="100%"
                                height="100%"
                                src={changeYoutubeUrl(article.video)}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              />
                            </div>
                          )
                          : <img className="article-image" src={`${process.env.REACT_APP_LANDING_API}${article.image}`} alt="" />
                      }
                    </div>
                    {/* eslint-disable-next-line */}
                    <p className="description" dangerouslySetInnerHTML={{__html: article.content}} />
                  </section>
                </section>
              </>
            )
            : <Spinner />
        }
      </div>

      <Footer />
    </>
  )
}

export default Article
