import React from 'react'
import {store as NotificationStore} from 'react-notifications-component'
import './style.scss'

const Notification = params => {
  const {
    container,
    message,
    type,
    title,
  } = params

  const renderIcon = () => {
    let icon = 'check_circle'

    if (type === 'danger') {
      icon = 'cancel'
    } else if (type === 'info') {
      icon = 'info'
    }

    return icon
  }

  const content = () => (
    <div className={`notification-component-style ${type || ''}`}>
      <div className="notification-icon">
        <span className="material-icons">
          {renderIcon()}
        </span>
      </div>
      <div className="notification-content">
        <p className="title">
          {title}
        </p>
        <p className="description">{message}</p>
      </div>
      <div className="notification-close">
        <span className="material-icons">close</span>
      </div>
    </div>
  )

  return NotificationStore.addNotification({
    container,
    content: content(),
    insert: 'top',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
    },
    slidingExit: {
      duration: 50,
      timingFunction: 'ease-out',
      delay: 0,
    },
  })
}

export default Notification
