import React, {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import _ from 'underscore'

import Spinner from 'components/Spinner'
import Selector from 'components/Selector'
import SecondHeader from 'components/SecondHeader'
import Footer from 'components/Footer'
import Notification from 'components/Notification'

import {languages} from 'constants/locales'
import {fetchCountries} from 'api/countries'
import {registration} from 'api/auth'
import validate from 'utils/validate'
import useForm from 'utils/hooks'

import './style.scss'

function Register() {
  const {t} = useTranslation()
  const [countries, setCountries] = useState(null)
  const [loading, setLoading] = useState(false)
  const [registerStatus, setRegisterStatus] = useState(false)

  const defaultData = {
    gender: 'm',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    mobile: '',
    language: null,
    company: '',
    country: '',
    firstAddress: '',
    secondAddress: '',
    commentary: '',
  }

  useEffect(() => {
    fetchCountries()
      .then(({data}) => {
        setCountries(data)
      })
  }, [])

  const submitRegistration = () => {
    const data = {
      gender: values.gender,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: +values.phone,
      mobile: +values.mobile,
      language: values.language,
      company: values.company,
      country: +values.country,
      first_address: values.firstAddress,
      second_address: values.secondAddress,
      commentary: values.commentary,
    }

    setLoading(true)
    registration(data)
      .then(() => {
        setRegisterStatus(true)
        setLoading(false)
      })
      .catch(error => {
        Notification({
          container: 'top-right',
          message: error?.response?.data?.email ? t(error.response.data.email[0]) : t('SOMETHING_WENT_WRONG'),
          type: 'danger',
          title: t('ERROR'),
        })
        setLoading(false)
      })
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submitRegistration, validate, defaultData)

  return (
    <>
      <SecondHeader />

      <div className={`register-page ${countries ? '' : 'register-spinner'}`}>
        {
          countries
            ? (
              <>
                <div className="register-title text-center">{t('REGISTRATION_REQUEST')}</div>

                <div className="container form-wrapper register">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-9 form p-0">
                      {
                        registerStatus
                          ? (
                            <div className="registration-success">
                              <span className="title">Thanks for filling out our form!</span>
                              {/* eslint-disable-next-line */}
                              <p className="big">One of our colleagues will get back in touch with you soon! We will send you an email with all data for authorization at the email address you provided.</p>
                              <p>The Amerigo Parts Team</p>
                              <div className="row actions">
                                <div className="col-12">
                                  <NavLink className="button-style button-style-hover btn-block" to="/">
                                    Back to home page
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          )
                          : (
                            <>
                              <form method="post" noValidate>
                                <fieldset className="personal-information">
                                  <span className="info">{t('PERSONAL_INFORMATION')}</span>
                                  <div className="gender">
                                    <span className="gender-title">{t('GENDER')}</span>
                                    <label className="gender-container">
                                      {t('MR')}
                                      <input
                                        type="radio"
                                        checked={values.gender === 'm'}
                                        name="gender"
                                        onChange={handleChange}
                                        value="m"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                    <label className="gender-container">
                                      {t('MME')}
                                      <input
                                        type="radio"
                                        checked={values.gender === 'f'}
                                        name="gender"
                                        onChange={handleChange}
                                        value="f"
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </div>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.firstName}
                                      name="firstName"
                                      className={`form-control ${errors.firstName && 'is-invalid'}`}
                                      placeholder={t('FIRST_NAME')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.firstName
                                        ? <p className="text-danger">{t(errors.firstName)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.lastName}
                                      name="lastName"
                                      className={`form-control ${errors.lastName && 'is-invalid'}`}
                                      placeholder={t('LAST_NAME')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.lastName
                                        ? <p className="text-danger">{t(errors.lastName)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <input
                                      type="email"
                                      value={values.email}
                                      name="email"
                                      className={`form-control ${errors.email && 'is-invalid'}`}
                                      placeholder="E-mail"
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.email
                                        ? <p className="text-danger">{t(errors.email)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.phone}
                                      name="phone"
                                      className={`form-control ${errors.phone && 'is-invalid'}`}
                                      placeholder={t('PHONE_NUMBER')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.phone
                                        ? <p className="text-danger">{t(errors.phone)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.mobile}
                                      name="mobile"
                                      className={`form-control ${errors.mobile && 'is-invalid'}`}
                                      placeholder={t('MOBILE_PHONE_NUMBER')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.mobile
                                        ? <p className="text-danger">{t(errors.mobile)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <Selector
                                      title={values.language || t('LANGUAGE')}
                                      selectId="select-languages"
                                      value={languages.code}
                                      data={languages}
                                      selectHandler={handleChange}
                                      name="language"
                                    />
                                    {
                                      errors.language
                                        ? <p className="text-danger">{t(errors.language)}</p>
                                        : null
                                    }
                                  </div>
                                </fieldset>
                                <fieldset className="work-information">
                                  <span className="info">{t('COMPANY_INFORMATION')}</span>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.company}
                                      name="company"
                                      className={`form-control ${errors.company && 'is-invalid'}`}
                                      placeholder={t('COMPANY')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.company
                                        ? <p className="text-danger">{t(errors.company)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.firstAddress}
                                      name="firstAddress"
                                      className={`form-control ${errors.firstAddress && 'is-invalid'}`}
                                      placeholder={t('ADDRESS_LINE_1')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.firstAddress
                                        ? <p className="text-danger">{t(errors.firstAddress)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className="control">
                                    <input
                                      type="text"
                                      value={values.secondAddress}
                                      name="secondAddress"
                                      className={`form-control ${errors.secondAddress && 'is-invalid'}`}
                                      placeholder={t('ADDRESS_LINE_2')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.secondAddress
                                        ? <p className="text-danger">{t(errors.secondAddress)}</p>
                                        : null
                                    }
                                  </div>

                                  <div className={`control country ${!_.isEmpty(errors.country) ? 'control-error' : ''}`}>
                                    <Selector
                                      title={values.country || t('COUNTRY')}
                                      selectId="select-countries"
                                      value={countries.id}
                                      data={countries}
                                      selectHandler={handleChange}
                                      name="country"
                                      required={_.isEmpty(values.country) ? 'required' : 'false'}
                                      error={errors.country}
                                    />
                                    {
                                      errors.country
                                        ? <p className="text-danger">{t(errors.country)}</p>
                                        : null
                                    }
                                  </div>

                                  <span className="info">{t('ANYTHING_ELSE_WE_SHOULD_KNOW')}</span>

                                  <div className="control">
                                    <textarea
                                      value={values.commentary}
                                      name="commentary"
                                      className={`form-control ${errors.commentary && 'is-invalid'}`}
                                      placeholder={t('WRITE_YOUR_MESSAGE')}
                                      onChange={handleChange}
                                    />
                                    {
                                      errors.commentary
                                        ? <p className="text-danger">{t(errors.commentary)}</p>
                                        : null
                                    }
                                  </div>
                                </fieldset>
                              </form>

                              <div className="row actions">
                                <div className="col-12">
                                  <button
                                    className={`button-style btn-block ${loading ? 'button-disabled-style' : 'button-style-hover'}`}
                                    type="submit"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                  >
                                    {
                                      loading
                                        ? (
                                          <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        )
                                        : null
                                    }
                                    <span>{t('REGISTER')}</span>
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                      }
                    </div>
                  </div>
                </div>
              </>
            ) : <Spinner />
        }
      </div>

      <Footer />
    </>
  )
}

export default Register
