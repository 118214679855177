import _ from 'underscore'

export default function validate(values) {
  const errors = {}
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!values.email) {
    errors.email = 'EMPTY_EMAIL'
  } else if (!emailRegEx.test(String(values.email).toLowerCase())) {
    errors.email = 'INVALID_EMAIL'
  }

  if (!values.firstName) {
    errors.firstName = 'EMPTY_FIRST_NAME'
  } else if (values.firstName.length < 3) {
    errors.firstName = 'INVALID_MIN_FIRST_NAME'
  } else if (values.firstName.length > 32) {
    errors.firstName = 'INVALID_MAX_FIRST_NAME'
  }

  if (!values.lastName) {
    errors.lastName = 'EMPTY_LAST_NAME'
  } else if (values.lastName.length < 3) {
    errors.lastName = 'INVALID_MIN_LAST_NAME'
  } else if (values.lastName.length > 32) {
    errors.lastName = 'INVALID_MAX_LAST_NAME'
  }

  if (!_.isEmpty(values.phone) && values.phone.toString().length <= 5) {
    errors.phone = 'INVALID_MIN_PHONE'
  } else if (!_.isEmpty(values.phone) && values.phone.toString().length > 16) {
    errors.phone = 'INVALID_MAX_PHONE'
  }

  if (!_.isEmpty(values.mobile) && values.mobile.toString().length <= 5) {
    errors.mobile = 'INVALID_MIN_MOBILE'
  } else if (!_.isEmpty(values.mobile) && values.mobile.toString().length > 16) {
    errors.mobile = 'INVALID_MAX_MOBILE'
  }

  if (!values.country) {
    errors.country = 'INVALID_COUNTRY'
  }

  if (!values.company) {
    errors.company = 'INVALID_СOMPANY'
  }

  if (values.company.length > 32) {
    errors.company = 'INVALID_MAX_COMPANY'
  }

  if (values.firstAddress.length > 70) {
    errors.firstAddress = 'INVALID_MAX_FIRST_ADDRESS'
  }

  if (values.secondAddress.length > 70) {
    errors.secondAddress = 'INVALID_MAX_LAST_ADDRESS'
  }

  if (values.commentary.length > 3000) {
    errors.commentary = 'INVALID_MAX_COMMENTARY'
  }

  return errors
}
