import ar from 'languages/ar'
import en from 'languages/en'
import es from 'languages/es'
import fr from 'languages/fr'
import pt from 'languages/pt'
import ru from 'languages/ru'
import {initReactI18next} from 'react-i18next'
import i18n from 'i18next'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {...en},
  },
  ar: {
    translation: {...ar},
  },
  es: {
    translation: {...es},
  },
  fr: {
    translation: {...fr},
  },
  ru: {
    translation: {...ru},
  },
  pt: {
    translation: {...pt},
  },
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.getItem('language') || 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false,
    },
  })
export default i18n
