import {useState, useEffect} from 'react'
import _ from 'underscore'

const useForm = (callback, validate, defaultData) => {
  const [values, setValues] = useState(defaultData)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback()
    }
    // eslint-disable-next-line
  }, [errors])

  const handleSubmit = event => {
    if (event) event.preventDefault()
    setErrors(validate(values))
    setIsSubmitting(true)
  }

  const handleChange = event => {
    event.persist()

    if (event.target.name === 'phone' || event.target.name === 'mobile') {
      const re = /^[0-9\b]+$/
      if (event.target.value === '' || re.test(event.target.value)) {
        setValues(values => ({...values, [event.target.name]: event.target.value}))
      }
    } else {
      setValues(values => ({...values, [event.target.name]: event.target.value}))
    }

    if (!_.isEmpty(event.target.value)) {
      delete errors[event.target.name]
      setErrors(errors)
    }
  }

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
}

export default useForm
