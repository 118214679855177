import request from 'utils/request'

export function fetchArticles(query = {}) {
  return request({
    url: '/news',
    method: 'get',
    params: query,
  })
}

export function fetchArticleByID(id) {
  return request({
    url: `/news/${id}`,
    method: 'get',
  })
}
