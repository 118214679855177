import React from 'react'
import './style.scss'
import {useTranslation} from 'react-i18next'


function Footer() {
  const {t} = useTranslation()

  return (
    <div className="wrapper-footer">
      <div className="footer">
        <div className="item"><a href="#">{t('LEGAL_NOTICE')}</a></div>
        <div className="item"><a href="#">{t('GENERAL_TERMS_AND_CONDITIONS')}</a></div>
        <div className="item"><a href="#">FAQ</a></div>
        <div className="item"><a href="#">{t('DATA_PRIVACY_AND_COOKIES')}</a></div>
        <div className="item"><a href="#">{t('NEWS')}</a></div>
        <div className="item"><a href="#">{t('CONTACT')}</a></div>
      </div>
    </div>
  )
}

export default Footer
