import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import _ from 'underscore'

import Spinner from 'components/Spinner'
import {changeYoutubeUrl} from 'utils'
import {fetchArticles} from 'api/articles'

import './style.scss'

function Articles() {
  const [currentPage, setCurrentPage] = useState(1)
  const [articles, setArticles] = useState(null)
  const [loading, setLoading] = useState(false)
  const {t, i18n} = useTranslation()

  useEffect(() => {
    fetch()
  }, [i18n.language])

  const fetch = (query = {}) => {
    setLoading(true)
    fetchArticles(query)
      .then(({data}) => {
        setArticles(data)
        setLoading(false)
      })
  }

  const handlerShowMore = event => {
    event.preventDefault()

    const {per_page} = articles
    setCurrentPage(prevState => prevState + 1)

    fetchArticles({
      per_page,
      page: currentPage + 1,
    })
  }

  const renderShowMore = () => {
    const {total_pages} = articles

    if (total_pages === currentPage) {
      return null
    }

    if (loading) {
      return <Spinner />
    }

    return (
      <div className="wrapper-show-more">
        <button
          className="button"
          type="button"
          onClick={event => handlerShowMore(event)}
        >
          {t('SHOW_MORE')}
        </button>
      </div>
    )
  }

  if (articles && _.isEmpty(articles.data)) {
    return ''
  }

  return (
    <section className="posts cards" id="articles">
      <h4 className="title">{t('OUR_NEWS')}</h4>
      {
        _.isEmpty(articles)
          ? <Spinner />
          : _.isEmpty(articles.data)
            ? <div className="no-data">{t('NO_NEWS')}</div>
            : (
              <div className="container">
                <div className="row">
                  <div className="col">
                    {
                      articles.data.map((item, index) => {
                        const id = index + 1
                        const image = `${process.env.REACT_APP_LANDING_API}${item.image}`

                        const renderImageAndVideo = item => (
                          item.video && item.video.length > 0
                            ? (
                              <iframe
                                title="video"
                                width="100%"
                                height="100%"
                                src={changeYoutubeUrl(item.video)}
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              />
                            )
                            : <img src={image} alt="" />
                        )

                        return (
                          <div key={item.id} className={`card ${id % 2 ? 'card-left' : ''}`}>
                            <div className="card-text">
                              <h2 className="card-title">{item.title}</h2>
                              {/* eslint-disable-next-line */}
                              <p className="card-description" dangerouslySetInnerHTML={{__html: item.content}} />
                              <div className="image">
                                {renderImageAndVideo(item)}
                              </div>
                              <NavLink
                                className="button"
                                to={`/article/${item.id}`}
                              >
                                {t('READ_MORE')}
                              </NavLink>
                            </div>
                            <div className="card-image">
                              {renderImageAndVideo(item)}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {loading ? <div style={{marginTop: '30px'}}><Spinner /></div> : renderShowMore()}
                </div>
              </div>
            )
      }
    </section>
  )
}

export default Articles
