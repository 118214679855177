import React from 'react'

import Header from 'components/Header'
import Catalogs from 'components/Catalogs'
import Articles from 'components/Articles'
import Footer from 'components/Footer'

import './style.scss'

function HomePage() {
  return (
    <>
      <Header />
      <Catalogs />
      <Articles />
      <Footer />
    </>
  )
}

export default HomePage
