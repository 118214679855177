import React from 'react'
import './style.scss'

const Spinner = () => (
  <div className="spinner">
    <svg viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" />
    </svg>
  </div>
)

export default Spinner
