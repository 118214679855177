import React from 'react'
import {useTranslation} from 'react-i18next'
import './style.scss'

function Catalogs() {
  const {t} = useTranslation()

  return (
    <section id="catalogs" className="catalogs cards">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card project card-left">
              <div className="card-text">
                <h2 className="card-title">AMERIGO International</h2>
                <p className="card-description">{t('AMERIGO_CONTENT')}</p>
                <div className="image">
                  <img src="/images/homepage/projects/amerigo.png" alt="" />
                </div>
                <a className="button" rel="noopener noreferrer" href={process.env.REACT_APP_AMERIGO_LINK} target="_blank">AMERIGO International</a>
              </div>
              <div className="card-image">
                <img src="/images/homepage/projects/amerigo.png" alt="" />
              </div>
            </div>
            <div className="card project">
              <div className="card-text">
                <h2 className="card-title">Amerigo Parts</h2>
                <p className="card-description">{t('AUTOMOTOR_CONTENT')}</p>
                <div className="image">
                  <img src="/images/homepage/projects/automotor.png" alt="" />
                </div>
                <a className="button" rel="noopener noreferrer" href={process.env.REACT_APP_AUTOMOTOR_LINK} target="_blank">Amerigo Parts</a>
              </div>
              <div className="card-image">
                <img src="/images/homepage/projects/automotor.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Catalogs
