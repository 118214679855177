export const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'ru',
    name: 'Русский',
  },
  {
    code: 'es',
    name: 'Español',
  },
  {
    code: 'ar',
    name: 'العربية',
  },
]

export const countries = [
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'RU',
    name: 'Russia',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
]
