export function landingPagemenu() {
  document.querySelector('.menu-icon').addEventListener('click', () => {
    document.querySelector('.menu-mobile').classList.add('show')
  })

  document.querySelector('.close-icon').addEventListener('click', () => {
    document.querySelector('.menu-mobile').classList.remove('show')
  })

  const list = document.getElementById('list')

  if (list) {
    list.addEventListener('click', e => {
      if (e.target.tagName === 'A') {
        document.querySelector('.menu-mobile').classList.remove('show')
      }
    })
  }
}

export function changeYoutubeUrl(currentUrl) {
  let url = ''

  if (currentUrl.includes('youtu.be')) {
    url = currentUrl.replace('https://youtu.be', 'https://www.youtube.com/embed/')
  } else {
    url = currentUrl.replace('watch?v=', 'embed/')
  }

  return url
}
