import React, {useEffect, useState} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {withRouter, NavLink, Link} from 'react-router-dom'
import $ from 'jquery'

import {landingPagemenu} from 'utils'
import SmoothScroll from 'utils/smooth-scroll.polyfills.min'
import {languages} from 'constants/locales'

import './style.scss'

function Header() {
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const [currentLanguage, setCurrentLanguage] = useState(language || '')

  useEffect(() => {
    changeLanguage(i18n.language)
  }, [i18n.language])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const scroll = new SmoothScroll('a[href*="#"]')

    landingPagemenu()

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth

    if (viewportWidth <= 550) {
      if (/Android|webOS|BlackBerry/i.test(navigator.userAgent)) {
        $('select').selectpicker('mobile')
      }

      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        $.fn.selectpicker.Constructor.DEFAULTS.mobile = true
      }
    }
  }, [i18n.language])

  const changeLanguage = (language = '') => {
    localStorage.setItem('landing-lang', language)
    setCurrentLanguage(language)
    i18n.changeLanguage(language)
  }

  const renderLanguages = () => (
    <div className="dropdown">
      <a className="dropdown-button">{languages.find(lang => lang.code === currentLanguage).name}</a>
      <div className="dropdown-content">
        {
          languages.map((language, index) => (
            <option
              className={`dropdown-item ${currentLanguage === language.code ? 'active' : ''}`}
              key={index}
              onClick={e => changeLanguage(e.target.value)}
              value={language.code}
            >
              {language.name}
            </option>
          ))
        }
      </div>
    </div>
  )

  return (
    <header id="header">
      <ul className="cb-slideshow">
        <li><span /></li>
        <li><span /></li>
        <li><span /></li>
        <li><span /></li>
        <li><span /></li>
        <li><span /></li>
      </ul>
      <div className="background-opacity">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="top-menu">
                <span className="menu-icon" />
                <div className="logo">
                  <Link to="/"><img src="/images/e-reliable.png" alt="" /></Link>
                </div>
                <div className="menu">
                  <ul>
                    <li><NavLink to="/register">{t('REGISTRATION')}</NavLink></li>
                    <li><a href="#catalogs">{t('CATALOGS')}</a></li>
                    <li><a href="#articles">{t('NEWS')}</a></li>
                  </ul>
                  {renderLanguages()}
                </div>
              </div>
              <div className="container">
                <h1 className="title">
                  <Trans><span>{t('HEADER_TITLE')}</span></Trans>
                </h1>
                <p className="subtitle">
                  <Trans><span>{t('HEADER_CONTENT')}</span></Trans>
                </p>
                <a className="button" href="#catalogs">{t('HEADER_BUTTON')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-mobile">
        <div className="wrapper">
          <span className="close-icon" />
          <div className="wrapper-menu-items">
            <ul id="list">
              <li><NavLink to="/register">{t('REGISTRATION')}</NavLink></li>
              <li><a href="#catalogs">{t('CATALOGS')}</a></li>
              <li><a href="#articles">{t('NEWS')}</a></li>
            </ul>
            {renderLanguages()}
          </div>
        </div>
      </div>
    </header>
  )
}

export default withRouter(Header)
