import React, {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {languages} from 'constants/locales'
import {landingPagemenu} from 'utils'

import './style.scss'

function Header() {
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const [currentLanguage, setCurrentLanguage] = useState(language || '')

  useEffect(() => {
    landingPagemenu()
  }, [i18n.language])

  useEffect(() => {
    changeLanguage(i18n.language)
  }, [i18n.language])

  const changeLanguage = (language = '') => {
    setCurrentLanguage(language)
    i18n.changeLanguage(language)
    localStorage.setItem('landing-lang', language)
  }

  const renderLanguages = () => (
    <div className="dropdown">
      <a className="dropdown-button">{languages.find(lang => lang.code === currentLanguage).name}</a>
      <div className="dropdown-content">
        {
          languages.map((language, index) => (
            <option
              className={`dropdown-item ${currentLanguage === language.code ? 'active' : ''}`}
              key={index}
              onClick={e => changeLanguage(e.target.value)}
              value={language.code}
            >
              {language.name}
            </option>
          ))
        }
      </div>
    </div>
  )

  return (
    <header id="second-header">
      <nav>
        <span className="menu-icon" />
        <div>
          <NavLink to="/"><img src="/images/e-reliable.png" alt="" /></NavLink>
        </div>

        <div className="nav-items">
          <ul>
            <li><NavLink to="/">{t('HOME')}</NavLink></li>
          </ul>
          {renderLanguages()}
        </div>
      </nav>

      <div className="menu-mobile">
        <div className="wrapper">
          <span className="close-icon" />
          <div className="wrapper-menu-items">
            <ul id="list">
              <li><NavLink to="/">{t('HOME')}</NavLink></li>
            </ul>
            {renderLanguages()}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
