import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import $ from 'jquery'

import store from 'store'
import * as serviceWorker from './serviceWorker'
import App from './App'
import './i18n'

// eslint-disable-next-line
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
// eslint-disable-next-line
import '../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js'
import './index.scss'

$.fn.selectpicker.Constructor.BootstrapVersion = '4'

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
