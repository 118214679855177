import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import ReactNotification from 'react-notifications-component'

import HomePage from 'pages/HomePage'
import Register from 'pages/Register'
import Article from 'pages/Article'
import i18n from './i18n'

function App() {
  const language = localStorage.getItem('landing-lang') || 'en'
  i18n.changeLanguage(language)
  localStorage.setItem('landing-lang', language)

  return (
    <>
      <ReactNotification isMobile={false} />

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/register" component={Register} />
        <Route path="/article/:id" component={Article} />
        <Redirect to="/" />
      </Switch>
    </>
  )
}

export default App
