import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {isEmpty} from 'underscore'
import $ from 'jquery'

const Selector = ({data, loading, selectId, title, translate, selectHandler, value, disabledState, name}) => {
  const {t, i18n} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    if (selectId) {
      $(`#${selectId}`).selectpicker({title})
    }
  }, [title])

  useEffect(() => {
    if (selectId) {
      $(`#${selectId}`)
        .selectpicker({title})
        .selectpicker('refresh')
    }
  }, [i18n.language])

  useEffect(() => {
    // refresh if select have data for options
    if (data && data.length) {
      $('.selectpicker').selectpicker('refresh')
    }
  }, [data])

  const renderOption = options => options.map((item, index) => (
    <option className="dropdown-item" key={index} value={item.id || item.code || item}>
      {
        translate
          ? t(item.description)
          : item.name || item.description || item
      }
      {item.hp ? ` | ${item.hp} hp` : null}
    </option>
  ))

  return (
    <select
      className="selectpicker"
      id={selectId}
      data-width="auto"
      data-size="10"
      data-actions-box="true"
      data-dropdown-align-right={selectId === 'select-version' ? true : 'auto'}
      onChange={selectHandler}
      value={value}
      title={t(title)}
      disabled={disabledState}
      name={name}
    >
      {
        isLoading
          ? <option className="dropdown-item center" disabled>{t('LOADING')}</option>
          : isEmpty(data)
            ? <option className="dropdown-item center" disabled>{t('NO_DATA')}</option>
            : renderOption(data)
      }
    </select>
  )
}

export default Selector
